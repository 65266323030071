<template>
  <div>
    <div class="text_right switchList">
      <el-button icon="el-icon-download" :disabled="loading" size="small" @click="downloadData()">批量下载</el-button>
      <div class="quehuan">
        <el-button :disabled="loading" :type="listType ? '' : 'primary'" @click="listType = false" class="buttineq1" size="small">卡片</el-button>
        <el-button :disabled="loading" :type="listType ? 'primary' : ''" @click="listType = true" class="buttineq2" size="small">列表</el-button>
      </div>
    </div>
    <el-table
        v-show="listType"
        v-loading="loading"
        height="calc(100vh - 340px)"
        :data="mp3DataList"
        size="small"
        ref="multipleTable"
        class="table"
        :row-key="'id'"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="netUrl" label="文件预览">
        <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="require('@/assets/img/collAudio.png')"
                :fit="'fill'"
                @click="previewPdf(scope.row.netUrl)"
            ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200px"></el-table-column>
      <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="text"
                     @click="previewPdf(scope.row.netUrl)">
            查看
          </el-button>
          <el-button size="mini" type="text"
                     @click="downFiile(scope.row.netUrl,scope.row.fileName)">
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listType" v-loading="loading">
      <el-row :gutter="10" style="height: calc(100vh - 330px);overflow-y: auto;width: 100%">
        <div v-if="mp3DataList.length">
          <el-col :span="4" v-for="item in mp3DataList" :key="item.id">
            <el-card
                :class="['box-card',dataListSelect.filter(item2=>item2.id == item.id).length == 1 ? 'selectDa' : '']">
              <div @click="selectData(item)" class="xhuanze">
                <el-image
                    style="width: 100%; height: 150px"
                    :src="require('@/assets/img/collAudio.png')"
                    :fit="'fill'"
                ></el-image>
                <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
                  <div class="omit fileName">{{ item.fileName }}</div>
                </el-tooltip>
              </div>
              <div class="flex_b_c">
                <el-button size="mini" type="text"
                           @click="previewPdf(item.netUrl)">
                  查看
                </el-button>
                <el-button size="mini" type="text"
                           @click="downFiile(item.netUrl,item.fileName)">
                  下载
                </el-button>
              </div>
            </el-card>
          </el-col>
        </div>
        <div class="noAccessory" v-else>
          <img src="@/assets/img/noData.png">
          <div>暂无相关数据</div>
        </div>
      </el-row>
    </div>

    <div class="text_center">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {Base64} from 'js-base64'

export default {
  name: "audioFrequency",
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },

    //typePage：审核页面的标识(通过和驳回时用)
    dir: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listType: false,
      loading:false,
      mp3DataList: [],
      dataListSelect:[],
      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.getPicture()
  },

  methods: {
    getPicture(dividePage) {
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.collection.collectionfileListDyygFile, {
        fileType:2,
        dataId: this.id,
        current:this.current,
        size:this.size,
      }, 'get').then((data) => {
        if (data.status) {
          this.loading = false
          this.mp3DataList = data.data.records
          this.total = parseInt(data.data.total)
        }
      })
    },

    //预览
    previewPdf(url) {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(url)));
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    //列表选中
    selectData(item) {
      let data = this.dataListSelect.filter(item2 => {
        return item2.id == item.id
      })
      if (data.length == 1) {
        this.$refs.multipleTable.toggleRowSelection(item, false);
      } else {
        this.$refs.multipleTable.toggleRowSelection(item);
      }
    },

    //批量下载
    downloadData(){
      if (!this.dataListSelect.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = []
      ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.collectionfileUrlDyygZip, {
        type:2,
        ids,
      }, 'get','blob').then((res) => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        let filename = '藏品音频'
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    downFiile(url, name) {
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },


    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.current = 1
      this.getPicture(1);
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getPicture();
    },
  },
}
</script>

<style scoped>
.switchList {
  display: flex;
  justify-content: right;
  align-items: center;
  .quehuan{
    margin-left: 15px;
    .buttineq1{
      border-radius: 3px 0 0 3px;
    }
    .buttineq2{
      border-radius: 0 3px 3px 0;
      margin: 0 !important;
    }
  }
}

.xhuanze{
  position: relative;
  .fileName{
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    height: 20px;
    color: #FFFFFF;
    background: rgba(0,0,0,.5);
  }
}

.box-card {
  margin-bottom: 10px;

  &::v-deep .el-card__body {
    padding: 10px !important;
  }
}

.noAccessory {
  text-align: center;

  img {
    width: 20%;
  }
}

.selectDa {
  border-color: #77B5FE;
  background: rgba(119, 181, 254, .05);
}
</style>